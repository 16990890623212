import React from "react"

const Copy = () => {
  const year = new Date().getFullYear()
  return (
    <footer className="page__copy" id="page__copy">
      <div className="container">
        <div className="content">
          <div className="grid">
            <p className="copy">
              &copy; {year} Bekap s.r.o. | Všechna práva vyhrazena
            </p>
            <p className="signature">
              Vytvořil{" "}
              <a
                href="https://michalhromas.com/"
                title="Přejít na michalhromas.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                michalhromas.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Copy
