import React from "react"
import Logo from "./../images/logo.svg"
import { Link } from "gatsby"

const Header = () => {
  return (
    <header className="page__header" id="page__header">
      <div className="container">
        <div className="content">
          <Link className="logo" title="Přejít na hlavní stránku" to="/">
            <img src={Logo} alt="Logo BEKAP s.r.o." />
          </Link>
          <nav>
            <ul>
              <li className="hidden--portrait">
                <a href="tel:+420602649362" title="Zavolat">
                  +420 602 649 362
                </a>
              </li>
              <li className="hidden--portrait">
                <a href="mailto:bemova@bekap.cz" title="Napsat e-mail">
                  bemova@bekap.cz
                </a>
              </li>
              <li className="home--only">
                <Link className="page-link" title="Zobrazit ceník" to="/cenik">
                  Ceník
                </Link>
              </li>
              <li className="home--hidden">
                <Link
                  className="page-link"
                  title="Zobrazit hlavní stránku"
                  to="/"
                >
                  Domů
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  )
}

export default Header
